import { ProductsCarousel } from "components/Product/Products/ProductsCarousel";
import { SectionTitle } from "components/shared/SectionTitle/SectionTitle";
import { useEffect, useState } from "react";
import client from "../../../../client";
import Spinner from "../../Spinner/Spinner";

export const MostViewed = ({ additionalClass }) => {
  const [products, setProducts] = useState([]);
  const mostViewed = [...products];
  const [loading, setLoading] = useState(true);
  // task => instead of getting normal products, show most sold products here
  useEffect(() => {
    const query = `*[_type == "Toy" && !(_id in path("drafts.**")) && isDraft == false][0..10]`;
    client.fetch(query).then((data) => {
      const discountedProducts = data.map((item) => {
        if (item.hasOwnProperty("discount")) {
          return {
            ...item,
            originalPrice: item.price,
            price: item.price - (item.discount / 100) * item.price,
          };
        } else {
          return { ...item, originalPrice: 0 };
        }
      });
      setProducts([...discountedProducts]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {/* <!-- BEGIN MOST VIEWED --> */}
      <section className={`arrivals ${additionalClass ? additionalClass : ""}`}>
        {/*{<SectionTitle subTitle="Toyz" title="You Have Viewed" />}*/}
        {
          <div className="products-items">
            <div className="products-carousel-title gradient-title">
              <h4>Most Viewed Products</h4>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <ProductsCarousel products={mostViewed} />
              </div>
            )}
          </div>
        }
      </section>
      {/* <!-- MOST VIEWED EOF --> */}
    </>
  );
};
