import { MdKeyboardArrowRight } from 'react-icons/md'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { BiAbacus } from 'react-icons/bi'
import LeftArrow from '../../../../public/assets/img/icons/LeftArrow'

export const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  pageType,
  style,
  ...props
}) => (
  <button
    {...props}
    id='prev'
    className={
      'slick-arrow slick-prev' +
        (currentSlide === 0 ? ' slick-disabled' : '') +
        ' ' +
        pageType ?? ''
    }
    aria-hidden='true'
    aria-disabled={currentSlide === 0 ? true : false}
    type='button'
  >
    {/*<i className="icon icon-arrow"></i>*/}
    <LeftArrow />
  </button>
)

export const SlickArrowNext = ({
  currentSlide,
  slideCount,
  pageType,
  ...props
}) => (
  <button
    {...props}
    className={
      'slick-arrow slick-next' +
        (currentSlide === slideCount - 1 ? ' slick-disabled ' : '') +
        ' ' +
        pageType ?? ''
    }
    aria-hidden='true'
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type='button'
  >
    {/*<i className="icon icon-arrow"></i>*/}
    {/*<MdKeyboardArrowRight />*/}
    {/*<BiAbacus />*/}
    <LeftArrow />
  </button>
)
