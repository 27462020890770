export const header = {
  logo: "/assets/img/header-logo.svg",
};

export const subNav = {
  name: "about",
  path: "/about",
};

export const navItem = [
  {
    name: "Shop",
    path: "/shop",
    // subNav:[
    //   {
    //     name : "Purchase Preloved",
    //     path:'purchase_preloved'
    //   },
    //   {
    //     name : "Sell Preloved",
    //     path : "sell_preloved"
    //   }
    // ]
  },
  {
    name: "About Us",
    path: "/about",
    subNav: [
      {
        name: "About Us",
        path: "about",
      },
      {
        name: "Why Toy Renting ?",
        path: "embrace_toy_rental",
      },
    ],
  },

  {
    name: "Subscription",
    path: "/how_it_work",
  },
  {
    name: "Gallery",
    path: "/gallery",
  },
  {
    name: "PartyArea",
    path: "/partyPlay",
  },

  {
    name: "Faq",
    path: "/faq",
  },
  {
    name: "Contact",
    path: "/contact",
  },
  {
    name: "Blogs",
    path: "/blogs",
  },

  // {
  //   name: "News",
  //   path: "/news",
  // },
];
