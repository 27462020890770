import { MostViewed } from "components/shared/MostViewed/MostViewed";
import { Wishlist } from "components/Wishlist/Wishlist";
import { PublicLayout } from "layout/PublicLayout";
import React, { useContext } from "react";
const breadcrumbsData = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Wishlist",
    path: "/wishlist",
  },
];
const WishlistPage = () => {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle="Wishlist">
      <Wishlist />
      <MostViewed />
    </PublicLayout>
  );
};


/*
* EXPORTS
*/
export default WishlistPage;
