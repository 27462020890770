import { Card } from './Card/Card';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

const instaData = [
  {
    image: '/assets/img/insta/1.png',
    link: 'www.instagram.com/jusplaytoys/',
    id: '1',
  },
  {
    image: '/assets/img/insta/2.png',
    link: 'www.instagram.com/jusplaytoys/',
    id: '2',
  },
  {
    image: '/assets/img/insta/3.png',
    link: 'www.instagram.com/jusplaytoys/',
    id: '3',
  },
  {
    image: '/assets/img/insta/4.png',
    link: 'www.instagram.com/jusplaytoys/',
    id: '4',
  },
  {
    image: '/assets/img/insta/5.jpg',
    link: 'www.instagram.com/jusplaytoys/',
    id: '5',
  },
  {
    image: '/assets/img/insta/6.jpg',
    link: 'www.instagram.com/jusplaytoys/',
    id: '6',
  },
];

const instaImages = [
  {
    image: '/assets/img/jusplay/_MG_0116.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/_MG_4678.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/1B5A8005.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/20230422_074945.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/20230512_143428.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/20230617_130927.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/20230411-WA0018.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/DSC_2236.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/image00017.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/IMG_0657.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/IMG_2483.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/YPP_5377.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/Image2023-06-26.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
  {
    image: '/assets/img/jusplay/Image2023-06-26-2.webp',
    link: 'www.instagram.com/jusplaytoys/',
  },
];

console.log('length', instaImages.length);

export const Insta = () => {
  return (
    <>
      {/* <!-- BEGIN INSTA PHOTOS --> */}
      <Swiper
        style={{
          '--swiper-pagination-color': '#1f69c4',
          '--swiper-pagination-bullet-inactive-color': '#999999',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '16px',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          marginTop: 50,
          paddingTop: 30,
          position: 'relative',
        }}
        slidesPerView={4}
        spaceBetween={5}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // loop={true}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 5,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[ Autoplay]}
        className='mySwiperSlide'
      >
        {instaImages.map((image, idx) => (
          <SwiperSlide key={idx}>
            {/*<div className="insta-photos">*/}
            {/*  <img src={image.image} alt="" />*/}
            {/*  <div className="insta-photo__hover">*/}
            {/*    <i className="icon-insta"></i>*/}
            {/*  </div>*/}
            {/*<Card key={image.id} insta={image} />*/}
            {/*</div>*/}
            <img src={image.image} alt='' style={{ cursor: 'pointer' }} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/*<div className="insta-photos">*/}
      {/*  {instaData.map((insta) => (*/}
      {/*    <Card key={insta.id} insta={insta} />*/}
      {/*  ))}*/}
      {/*</div>*/}
      {/* <!-- INSTA PHOTOS EOF   --> */}
    </>
  );
};
