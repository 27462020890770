import { Header } from "../components/shared/Header/Header";
import { Insta } from "components/shared/Insta/Insta";
import { Footer } from "components/shared/Footer/Footer";
import WhyUs from "components/shared/Footer/WhyUs/WhyUs";
// import "../styles/css/global.css";

export const Layout = ({ children, pageType }) => {
  return (
    <>
      <header className="header">
        <Header />
      </header>
      <main
        className="content"
        style={{ paddingBottom: pageType === "index" ? 0 : "" }}
      >
        {children}
        {/*<Insta />*/}
      </main>
      <footer className="footer">
        <Footer />
      </footer>
    </>
  );
};
