import { IoWalletOutline } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { TiSortAlphabeticallyOutline } from "react-icons/ti";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import { RiFileDamageLine } from "react-icons/ri";
import { GiMoneyStack } from "react-icons/gi";

export const whyUs = [
  {
    title: "Pocket-Friendly Fun",
    description: "Premium quality toys starting at just ₹100!",
    icon: <IoWalletOutline size={80} />,
  },
  {
    title: "Weekly Doorstep Delivery",
    description:
      "We’ll deliver and assemble your favorite toys right at your doorstep!",
    icon: <TbTruckDelivery size={80} />,
  },
  {
    title: "Play based development",
    description:
      "Foster your child's motor skills, hand-eye coordination, and imagination through engaging play!",
    icon: <TiSortAlphabeticallyOutline size={80} />,
  },
  {
    title: "Quality Assurance",
    description:
      "Enjoy high-quality products ,long -lasting quality on all working parts throughout your rental period.",
    icon: <HiOutlineCheckBadge size={80} />,
  },
  {
    title: "Damage Policy",
    description:
      "We don't charge for minor wear and tear, and if repairs are needed, we only ask you to cover half the cost—because we believe in keeping playtime stress-free!",
    icon: <RiFileDamageLine size={80} />,
  },
  {
    title: "Deposits",
    description:
      "For orders up to ₹3500, we collect a 100% refundable ₹1000 deposit, returned within 2-3 days after toy pickup—ensuring a smooth and worry-free experience!!",
    icon: <GiMoneyStack size={80} />,
  },
];
