import Link from "next/link";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../../client";
import { useContext } from "react";
import { CartContext } from "pages/_app";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Card = ({ wish, setWish }) => {
  const { name, mainImage, _id, isStocked, price, sku, slug } = wish;
  const { wishlist, setWishlist } = useContext(CartContext);

  // Get a pre-configured url-builder from your sanity client
  const builder = imageUrlBuilder(client);

  const _deleteItem = (id) => {
    const updatedWishlist = wishlist.filter((item) => item._id !== id);
    setWishlist([...updatedWishlist]);
    toast.error("Item Removed");
  };

  function urlFor(source) {
    return builder.image(source);
  }

  return (
    <>
      {/* <!-- BEGIN WISHLIST CARD --> */}
      <div className="cart-table__row">
        <div className="cart-table__col">
          <Link href={`/product/${slug.current}`}>
            <a className="cart-table__img">
              <img src={mainImage} className="js-img" alt="" />
            </a>
          </Link>
          <div className="cart-table__info">
            <Link href={`/product/${slug.current}`}>
              <a className="title5">{name}</a>
            </Link>
            <span className="cart-table__info-num">SKU: {sku}</span>
          </div>
        </div>
        <div className="cart-table__col">
          <span className="cart-table__price">₹{price}</span>
        </div>

        <div className="cart-table__col">
          <span className="cart-table__total">
            <Link href={`/product/${slug.current}`}>
              <a className="blog-item__link">
                RENT NOW <i className="icon-arrow-md"></i>
              </a>
            </Link>
          </span>
        </div>
        <div className="cart-table__col">
          <span
            onClick={() => _deleteItem(_id)}
            style={{ margin: "20px", cursor: "pointer" }}
            className="cart-table__total delete"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
              />
            </svg>
          </span>
          <ToastContainer />
        </div>
      </div>
      {/* <!-- WISHLIST CARD EOF--> */}
    </>
  );
};
