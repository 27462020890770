import { SearchHeader } from '../SearchHeader/SearchHeader'
import { header } from 'data/data.header'
import Link from 'next/link'
import { useContext, useEffect, useState, useRef } from 'react'
import { Nav } from './Nav/Nav'
import { navItem } from 'data/data.header'
import { CartContext } from 'pages/_app'
import { useSession } from 'next-auth/react'
import wishlist from '../../../pages/wishlist'
import { useRouter } from 'next/router'
import Image from 'next/image'
//import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';
import { ToastContainer, toast } from 'react-toastify'
import { IoCartOutline } from 'react-icons/io5'

export const Header = () => {
  const router = useRouter()
  const { pathname } = router
  const { wishlist } = useContext(CartContext)
  const [promo, setPromo] = useState(true)
  const [fixedNav, setFixedNav] = useState(false)
  const { status } = useSession()
  const [active, setActive] = useState(false)
  const [route, setRoute] = useState(null)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      btnRef.current.click()
      setLoading(true)
    }
  }

  const {
    subscriptionCart,
    cart,
    setCart,
    setSubscriptionCart,
    subscriptionPlan,
  } = useContext(CartContext)

  // For Fixed nav
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  const isSticky = () => {
    const scrollTop = window.scrollY
    if (scrollTop > 10) {
      setFixedNav(true)
    } else {
      setFixedNav(false)
    }
  }

  useEffect(() => {
    if (router) {
      console.log(router)
      setRoute(router.pathname)
    }
  }, [router])

  const headerBg = {
    background: route === '/' ? '#EAFCFE' : '#faecf3',
  }

  return (
    <>
      {/* <!-- BEGIN HEADER --> */}
      <header className='header' style={headerBg}>
        {promo && (
          <div className='header-top'>
            <span style={{ textAlign: 'center' }}>
              FIRSTIO: 10% DISCOUNT ON ALL PRODUCT FOR FIRST TIME USER
              <br />
              {/* Delivery and Pickups planned for 31st Dec and 1st Jan might be affected due to heavy traffic conditions and work overload */}
              {/* Happy Holi 🎨 - Orders booked for 25th would be delivered on 23rd/24th due to the Holi Rush! */}
            </span>
            <i
              onClick={() => setPromo(false)}
              className='header-top-close js-header-top-close icon-close'
            ></i>
          </div>
        )}
        <div className={`header-content ${fixedNav ? 'fixed' : ''}`}>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            className='hello'
          >
            <div className='header-logo'>
              <Link href='/'>
                <a>
                  <img
                    style={{ width: '100px', borderRadius: '100%' }}
                    src='/assets/img/jusplay/logo.jpeg'
                    alt='jusplayLogo'
                  />
                </a>
              </Link>
            </div>

            <li className='cart_new_icon display'>
              <Link
                href={
                  router.pathname !== '/subscriptionCart' &&
                  router.pathname !== '/basic' &&
                  router.pathname !== '/standard' &&
                  router.pathname !== '/premium' &&
                  router.pathname !== '/subscription/[id]'
                    ? '/cart'
                    : '/subscriptionCart'
                }
              >
                <a
                  style={{
                    color: '#222',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* <i className='icon-cart'></i> */}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    viewBox='0 0 512 512'
                  >
                    <circle
                      cx='176'
                      cy='416'
                      r='16'
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='32'
                    />
                    <circle
                      cx='400'
                      cy='416'
                      r='16'
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='32'
                    />
                    <path
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='32'
                      d='M48 80h64l48 272h256'
                    />
                    <path
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='32'
                      d='M160 288h249.44a8 8 0 0 0 7.85-6.43l28.8-144a8 8 0 0 0-7.85-9.57H128'
                    />
                  </svg>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 22,
                      height: 22,
                    }}
                  >
                    {router.pathname !== '/subscriptionCart' &&
                    router.pathname !== '/basic' &&
                    router.pathname !== '/standard' &&
                    router.pathname !== '/premium' &&
                    router.pathname !== '/subscription/[id]'
                      ? cart.length
                      : subscriptionCart.length}
                  </span>
                </a>
              </Link>
            </li>

            <div onClick={() => setActive(!active)} className='btn-menu'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                {' '}
                <path
                  d='M5 6H12H19M5 12H19M5 18H19'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                />{' '}
              </svg>
              {/* {[1, 2, 3].map((i) => (
              <span key={i}>&nbsp;</span>
            ))} */}
            </div>

            <div className={`header-box ${active ? 'active' : ''}`}>
              {/* Nav */}
              <Nav navItem={navItem} />
              {/* <Link href='/how_it_work'>
  <a className={'/how_it_work' === router.pathname ? 'active' : ''} style={{color:'#000',fontWeight:'500',marginRight:'5px',marginLeft:'5px'}}>
    How It Work
  </a>
</Link> */}

              {/* header options */}
              <ul className='header-items'>
                <li
                  className={`header-search-box ${
                    router.pathname === '/' ? 'blue' : null
                  }`}
                >
                  {/* <SearchHeader /> */}
                </li>
                <div className='header-options'>
                  <li>
                    <Link
                      href={status === 'authenticated' ? '/profile' : '/login'}
                    >
                      <a>
                        <i className='icon-user'></i>
                      </a>
                    </Link>
                  </li>
                  <li className='header-icon'>
                    <Link href='/wishlist'>
                      <a>
                        <i className='icon-heart'></i>
                        <span>{wishlist.length ?? '0'}</span>
                      </a>
                    </Link>
                  </li>
                  {router.pathname !== '/basic' &&
                  router.pathname !== '/standard' &&
                  router.pathname !== '/premium' &&
                  router.pathname !== '/subscriptionCart' &&
                  router.pathname !== '/subscription/[id]' ? (
                    <li
                      style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                      }}
                    >
                      <Link href='/cart'>
                        <a style={{ color: '#222', marginRight: '5px' }}>
                          {/* <i className='icon-cart'></i> */}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='25'
                            height='25'
                            viewBox='0 0 512 512'
                          >
                            <circle
                              cx='176'
                              cy='416'
                              r='16'
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                            />
                            <circle
                              cx='400'
                              cy='416'
                              r='16'
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                            />
                            <path
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                              d='M48 80h64l48 272h256'
                            />
                            <path
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                              d='M160 288h249.44a8 8 0 0 0 7.85-6.43l28.8-144a8 8 0 0 0-7.85-9.57H128'
                            />
                          </svg>
                          <span>
                            {[
                              '/subscriptionCart',
                              '/subscription/[id]',
                              '/subscriptionSearch',
                            ].includes(router.pathname)
                              ? subscriptionCart.length
                              : cart.length}
                          </span>
                        </a>
                      </Link>
                    </li>
                  ) : (
                    <li
                      style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                      }}
                    >
                      <Link href='/subscriptionCart'>
                        <a style={{ color: '#222', marginRight: '5px' }}>
                          {/* <i className='icon-cart'></i> */}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='25'
                            height='25'
                            viewBox='0 0 512 512'
                          >
                            <circle
                              cx='176'
                              cy='416'
                              r='16'
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                            />
                            <circle
                              cx='400'
                              cy='416'
                              r='16'
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                            />
                            <path
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                              d='M48 80h64l48 272h256'
                            />
                            <path
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='32'
                              d='M160 288h249.44a8 8 0 0 0 7.85-6.43l28.8-144a8 8 0 0 0-7.85-9.57H128'
                            />
                          </svg>
                          <span>{subscriptionCart.length}</span>
                        </a>
                      </Link>
                    </li>
                  )}
                </div>
              </ul>
            </div>
          </div>
          <div className='search-header-desktop'>
            <SearchHeader />
          </div>

          <div style={{ marginTop: '10px' }} className='mobile-search'>
            <SearchHeader />
          </div>
        </div>
      </header>

      {/* <!-- HEADER EOF   --> */}
    </>
  )
}
