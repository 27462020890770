import { useRouter } from 'next/router';
import Link from 'next/link';
import { useState } from 'react';
import { FaAngleDown } from "react-icons/fa";

export const Nav = ({ navItem }) => {
  const router = useRouter();
  const [active,setActive] = useState(false)

  return (
    <ul className='header-nav' style={{width:'68vw'}}>
      {navItem.map((nav) => (
        <li key={nav.path} >
      
          <Link href={nav.path}>
            <a className={nav.path === router.pathname ? 'active' : ''}  >
              {nav.name === 'Shop' ? <b>{nav.name}</b> : nav.name} {(nav.name === 'About Us' ) && <FaAngleDown onClick={()=>setActive(!active)}/>}
            </a>
           
          </Link>

         {!active && ( nav.subNav && (
            <ul style={{left:'10px'}}>
              {nav.subNav.map((sub) => (
                <li key={sub.path} style={{border:'1px solid #fcedea',padding:'5px'}}>
                  <Link href={sub.path}>
                    <a>{sub.name}</a>
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </li>
      ))}
    </ul>
  );
};