const LeftArrow = ({ width, height, style }) => {
  return (
    <>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="icon icon-arrow"
        style={style}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        ></path>
      </svg>
    </>
  );
};

export default LeftArrow;
