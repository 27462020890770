import productData from "data/product/product";
import { Card } from "./Card/Card";
import Link from "next/link";
import { CartContext } from "pages/_app";
import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Wishlist = () => {
  const wishItems = [...productData].slice(0, 2);
  wishItems[1].isStocked = false;
  const { wishlist, setWishlist } = useContext(CartContext);
  const notify = () => toast("Wow so easy!");
  const [render, setRender] = useState(false);
  const [products, setProducts] = useState([]);
  const [wishlists, setWishlists] = useState([]);
  const [wish, setWish] = useState([]);
  useEffect(() => {
    console.log(products, "products");
    setProducts([...wishlist]);
  }, [wishlist]);
  const clearWishlist = () => {
    setWishlist([]);
    setRender(!render);
    toast.error("Wishlist cleared");
  };
  useEffect(() => {
    console.log(wishlists, "wishlists");
  }, [render]);
  return (
    <>
      {/* <!-- BEGIN WISHLIST --> */}
      <div className="wishlist">
        <div className="wrapper">
          <div className="cart-table">
            <div className="cart-table__box">
              <div className="cart-table__row cart-table__row-head">
                <div className="cart-table__col">Product</div>
                <div className="cart-table__col">Price</div>
                <div className="cart-table__col">Rent Now</div>
                <div className="cart-table__col">Delete</div>
              </div>
              {
                wishlist.length === 0 ? <p>Wishlist is Empty</p> : null
              }
              {products.map((wish) => (
                <Card key={wish._id} wish={wish} setWish={setWish} />
              ))}
            </div>
          </div>
          <div className="wishlist-buttons">
            <a href="#" onClick={clearWishlist} className="btn btn-grey">
              clear Wishlist
            </a>
            <ToastContainer />
            <Link href="/shop">
              <a className="btn">go shopping</a>
            </Link>
          </div>
        </div>
        <img
          className="promo-video__decor js-img"
          data-src="/assets/img/promo-video__decor.jpg"
          alt=""
        />
      </div>
      {/* <!-- WISHLIST EOF   --> */}
    </>
  );
};
